main.results {
    padding-top: $lnh-m;

    @include breakpoint(tmd) {
        padding: 0 $spc-v-m-m;
    }

    @include breakpoint(sm) {
        padding-top: 0;//$lnh-m-m / 2;
    }

    section#heading {
        margin-bottom: $lnh-m / 2;

        h1 {
            letter-spacing: 0;
        }
    }

    .results-filter {
        list-style: none;

        li {
            &:before {
                content: '';
                display: inline-block;
                width: 13px;
                height: 13px;

                border: 1px solid black;
                border-radius: 1rem;;

                margin-right: $lnh-s / 2;
            }

            &.active,
            &:hover,
            &:focus {
                &:before {
                    background-color: $green;
                    border-color: $green;
                }
            }

            &.active {
                a {
                    font-weight: bold;
                }
            }

            a {
                color: black;
            }
        }
    }
    
    .section-divide {
        margin-top: $lnh-m * 2;
        margin-bottom: $lnh-m;

        border-bottom: 1.5px solid $green;

        @include breakpoint(sm) {
            margin-top: $lnh-m-m * 1;
            margin-bottom: $lnh-m-m;
        }

        &:first-child {
            margin-top: 0;
        }

        h2 {
            @include fontformat($fmt-m);
        }
    }

    article {
        padding: 32px;
        padding-bottom: 35px;

        margin-bottom: $gutter;

        border: 1px solid $green;
        border-radius: 4px;

        @include breakpoint(sm) {
            padding: $mobilePadding;
            padding-bottom: $mobilePadding + 9px;
            border: 1px solid $grey;
        }

        h3 {
            display: inline;
            color: $green;
        }

        p {
            color: black;
        }

        .title {
            margin-bottom: $lnh-s / 2;
        }

        .summary {
            font-size: 0.9em;

            // summary {
            //     font-weight: bold;
            //     list-style: none;

            //     &::-webkit-details-marker {
            //         display: none;
            //     }
            // }

            p {
                margin-bottom: 0;
            }
        }

        .dot {
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: $green;
            border-radius: 100%;

            @include breakpoint(sm) {
                vertical-align: -2px;
            }
        }

        &.publication {
            .summary {
                margin-bottom: $lnh-s;
            }
        }
    }

    .article-navigation {
        ul.nav-wrapper {
            @include breakpoint(sm) {
                justify-content: space-between;
            }

            li {
                margin: 0 $lnh-m;

                @include breakpoint(sm) {
                    margin: 0 $lnh-m-m / 2;
                }

                a.disabled {
                    pointer-events: none;
                }

                a:not(.disabled) {
                    &:hover,
                    &:focus {
                        svg path {
                            fill: black;
                        }
                    }

                    svg path {
                        fill: $green;
                    }
                }

                &#prev {
                    a svg {
                        transform: rotate(180deg);
                    }
                }

                &#end {
                    a svg {
                        transform: rotate(180deg);
                    }
                }

                &#counter {
                    font-weight: bold;
                    color: $green;
                }
            }
        }
    }
}