main#partner {
    h2 {
        @include fontformat($fmt-m);
        font-weight: bold;
        color: black;
        text-transform: none;

        @include breakpoint(sm) {
            @include fontformat($fmt-m-m);
        }
    }

    #partner-accordions {
        margin-top: 20px;

        overflow-x: hidden;

        .accordion {
            border: 1px solid $grey;
            border-radius: 4px;
            // border-bottom: 0;

            margin-bottom: 7px;
    
            // &:first-child {
            //     border-radius: 4px 4px 0 0;
            // }
    
            // &:last-child {
            //     border-bottom: 1px solid $grey;
            //     border-radius: 0 0 4px 4px;
            // }
    
            input:checked {
                & + label {
                    width: $absColumnWidth * 1;
    
                    #open {
                        display: none;
                    }
    
                    #close {
                        display: inline;
                    }
                }

                & ~ .accordion-content {
                    mask-image: none;
                }
            }
    
            label {
                position: absolute;
                width: 70%;
                height: 100%;
                top: 0;
                right: 0;
    
                line-height: 0;
                text-align: right;
    
                padding: 12px;

                z-index: 1;
    
                #close {
                    display: none;
                }
            }
    
            .accordion-content {
                max-height: 23px + $lnh-m * 6;
                display: flex;
    
                mask-image: linear-gradient(white calc(100% - #{$lnh-m * 2}), transparent 100%);

                @include breakpoint(sm) {
                    max-height: $mobilePadding + 56px + $lnh-m-m * 3 + $mobilePadding;
                    display: block;
                    // mask-image: none;
                }

                .channels svg path {
                    fill: $green;
                }
    
                .left {
                    flex: 0 0 33.33%;
                    max-width: $absColumnWidth * 3;
    
                    display: flex;
    
                    padding: 23px 30px;

                    @include breakpoint(sm) {
                        padding: $mobilePadding;
                    }

                    img {
                        max-height: 70px;
                        width: 90%;

                        object-fit: contain;
                        object-position: top left;

                        @include breakpoint(sm) {
                            height: 50px;
                            width: auto;
                            max-width: 80%;
                        }
                    }
                }
    
                .right {
                    flex: 0 0 60%;//$absColumnWidth * 6;
                    padding: 30px 0;

                    p:last-of-type {
                        margin-bottom: $lnh-m / 2;
                    }

                    @include breakpoint(sm) {
                        padding: 0 $mobilePadding;
                    }

                    .channel-icon {
                        background-color: $green;
                    }
                }
            }
        }
    }

    .partner-members {
        margin-top: $lnh-m * 1;
        margin-bottom: $lnh-m * 1;

        .team-member {
            margin-bottom: $lnh-m / 2;

            @include breakpoint(sm) {
                margin-bottom: $lnh-m-m / 2;
            }
        }
    }
}