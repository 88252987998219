.content-image {
    figure {
        figcaption {
            position: relative;
            padding: $lnh-xs / 2 0;

            background-color: white;

            @include breakpoint(sm) {
                margin-top: $lnh-xs-m / 2;
                color: $darkgrey;
                padding-bottom: $lnh-xs-m / 2;
                text-align: right;
            }
        }

        img {
            width: 100%;
            border-radius: 4px;
        }
    }
}