// TYPOGRAPHY

$fnt: "Inria", Helvetica, Arial, sans-serif;

/* Helvetica */

// $fnt-xl: 48px;
// $lnh-xl: 48px;
// $lsp-xl: -0.04em;

// $fmt-xl: $fnt-xl, $lnh-xl, $lsp-xl;

// $fnt-xl-m: 32px;
// $lnh-xl-m: 36px;
// $lsp-xl-m: -0.04em;

// $fmt-xl-m: $fnt-xl-m, $lnh-xl-m, $lsp-xl-m;

$fnt-l: 40px;
$lnh-l: 45px;
$lsp-l: 0em;

$fmt-l: $fnt-l, $lnh-l, $lsp-l;

$fnt-l-m: 30px;
$lnh-l-m: 32px;
$lsp-l-m: 0em;

$fmt-l-m: $fnt-l-m, $lnh-l-m, $lsp-l-m;

$fnt-m: 20px;//24px;
$lnh-m: 28px;//34px;
$lsp-m: 0em;

$fmt-m: $fnt-m, $lnh-m, $lsp-m;

$fnt-m-m: 17px;//18px;
$lnh-m-m: 25px;//24px;
$lsp-m-m: 0em;

$fmt-m-m: $fnt-m-m, $lnh-m-m, $lsp-m-m;

$fnt-s: 17px;//20px;
$lnh-s: 27px;//28px;
$lsp-s: 0em;

$fmt-s: $fnt-s, $lnh-s, $lsp-s;

$fnt-s-m: 17px;
$lnh-s-m: 25px;
$lsp-s-m: 0em;

$fmt-s-m: $fnt-s-m, $lnh-s-m, $lsp-s-m;

$fnt-xs: 15px;//18px;
$lnh-xs: 18px;//30px;
$lsp-xs: 0em;

$fmt-xs: $fnt-xs, $lnh-xs, $lsp-xs;

$fnt-xs-m: 12px;
$lnh-xs-m: 14.4px;
$lsp-xs-m: 0em;

$fmt-xs-m: $fnt-xs-m, $lnh-xs-m, $lsp-xs-m;

/// COLORS

$black: rgb(0, 0, 0);
$grey: #D9D9D9;
$darkgrey: #9A9A9A;
$green: #00A1AE;

/// GRID
$gutter: 20px;

$colcount: 12;
$colwidth: 100% / $colcount;
// $colwidth: calc((75vw - #{$gutter * 2}) / $colcount);

$contentWidth: 1512px;//1240px;//calc(1240px + #{$gutter * 2});
$absColumnWidth: $contentWidth / $colcount;

/// SPACES

$mobilePadding: 16px;
$mobileHeaderHeight: 36px;

$spc-v-l: 60px;
$spc-v-m: 39px;
$spc-v-s: 30px;

$spc-v-s-m: 14px;
$spc-v-m-m: 25px;
$spc-v-l-m: 3.2vw;

/// BREAKS

// $navBreak: 1290px;
$screen-xs-max: 768px;
$screen-sm-max: 980px;
$screen-md-max: 1280px;
$screen-lg-min: 1441px;
