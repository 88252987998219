.accordion {
    position: relative;

    .accordion-content {
        max-height: 110px;
        transition: max-height .5s ease;
        
        overflow: hidden;
    }

    input {
        display: none;

        &:checked {
            & ~ .accordion-content {
                max-height: 800px !important;
                max-height: var(--max-height) !important;
                transition: max-height .35s ease-in;
            }
        }
    }

    label {
        cursor: pointer;
    }
}