.project-credits {
    position: relative;
    height: 100%;

    display: flex;

    background: #F0F0F0;
    border: 1px solid #D7D7D7;
    border-radius: 4px;

    padding: 25px 22px;

    @include breakpoint(sm) {
        padding: 15px;
    }

    &.institution {
        .member-image img {
            object-fit: contain;
        }
    }

    .member-content-wrapper {
        margin-left: 22px;

        @include breakpoint(sm) {
            margin-left: 15px;
        }

        & > div {
            margin-bottom: $lnh-s / 2;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(sm) {
                margin-bottom: $lnh-s-m / 2;
            }
        }
    }

    .member-image {
        flex: 0 0 102px;
        height: 102px;
        line-height: 0;
        border-radius: 50%;

        @include breakpoint(sm) {
            flex: 0 0 75px;
            height: 75px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .member-name {
        a {
            color: black;

            @include breakpoint(sm) {
                text-decoration: underline;
            }
        }
    }

    .member-info {
        hyphens: auto;
    }

    // img#member-company-logo {
    //     display: block;
    //     // width: 60%;
    //     // max-width: 300px;
    //     max-height: $lnh-m * 3;
    //     width: auto;
    //     max-width: 100%;

    //     padding: $lnh-m / 4 0;
    //     margin-top: $lnh-m / 2;

    //     @include breakpoint(sm) {
    //         max-height: $lnh-m-m * 3;
    //         padding: $lnh-m-m / 4 0;
    //         margin-top: $lnh-m-m / 2;
    //     }
    // }

    .member-email {
        color: black;
        font-weight: bold;
        word-break: break-all;
        text-decoration: underline;
    }

    .member-channels {
        a {
            .channel-icon {
                margin-bottom: $lnh-m / 4;

                @include breakpoint(sm) {
                    margin-bottom: $lnh-m-m / 4;
                }
            }
        }
    }

    // .member-caret {
    //     text-align: right;

    //     cursor: pointer;

    //     &.expanded svg {
    //         transform: rotate(180deg);
    //     }
    // }
    
}