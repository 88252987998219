main.article {
    @include breakpoint(tmd) {
        padding: 0 $spc-v-m-m;
    }
    
    h3 {
        font-weight: normal;
    }

    .headline:after {
        opacity: 0;
    }

    .image-text {
        margin-bottom: $lnh-m;
    }

    .article-date {
        margin-top: $lnh-m * 2;
        margin-bottom: $lnh-m;

        border-bottom: 1.5px solid $green;
    }

    .article-content {
        position: relative;
    }

    .article-author {
        position: absolute;
        top: 0;
        left: 0;
        width: $absColumnWidth * 2;

        @include breakpoint(sm) {
            position: relative;

            display: flex;
            align-items: center;

            margin-bottom: $lnh-m-m;
        }

        img {
            width: 85px;
            height: 85px;
            border-radius: 100%;

            object-fit: cover;

            @include breakpoint(sm) {
                width: 50px;
                height: 50px;

                margin-right: $lnh-m-m;
            }
        }

        .author-name {
            margin: $lnh-xs / 2 0;

            @include breakpoint(sm) {
                margin: 0;
            }
        }
    }
}