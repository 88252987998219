main#home {
    padding-top: 43px;
    overflow-x: hidden;

    @include breakpoint(sm) {
        padding-top: 0;
    }

    #introduction {

        @include breakpoint(sm) {
            margin-top: $lnh-l / 2;
        }

        @include breakpoint(sm) {
            #home-hero-visual {
                margin-left: -$mobilePadding;
                margin-right: -$mobilePadding;
            }
        }

        #home-hero-logo {
            transform: translateY(-50%);
            
            @include breakpoint(sm) {
                .col-6 {
                    position: relative;
                    padding: 0 5%;
                }
            }

            svg {
                width: 100%;

                @include breakpoint(sm) {
                    height: 100%;
                }
            }
        }
    }

    #highlights {
        #highlights-title {
            margin-bottom: $lnh-m;
        }

        .highlight-item {
            display: flex;

            &:last-child {
                .highlight-line:after {
                    display: none;
                }
            }

            &.disabled {
                .green {
                    color: #ADADAD;
                }

                .highlight-line {
                    &:before {
                        background-color: #ADADAD;
                    }
    
                    &:after {
                        background-color: #ADADAD;
                    }
                }
            }

            .highlight-date {
                flex: 0 0 $colwidth * 2;
                text-align: center;
            }

            .highlight-line {
                position: relative;
                
                flex: 0 0 16px;
                margin: 0 $gutter;

                transform: translateY(7px);

                @include breakpoint(sm) {
                    margin-left: 0;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $green;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: $green;
                    position: absolute;
                    top: 0;
                    left: 50%;
                }
            }

            .highlight-content {
                div.highlight-body {
                    margin-top: $lnh-m / 2;
                    margin-bottom: $lnh-m * 2;

                    @include breakpoint(sm) {
                        margin-top: $lnh-m-m / 2;
                        margin-bottom: $lnh-m-m * 2;
                    }
                }
            }

            .highlight-date,
            .highlight-content h3 {
                font-size: 25px;
                font-weight: 300;

                @include breakpoint(sm) {
                    font-size: 22px;
                }
            }
        }
    }
}