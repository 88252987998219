body {
    display: flex;
    flex-flow: column;

    header {
        flex: 0 0 auto;
        width: 100%;
    }

    main {
        flex: 1;
        overflow-x: hidden;

        img {
            width: 100%;
        }
    }

    section {
        margin-bottom: 80px;

        @include breakpoint(sm) {
            padding: 0 $mobilePadding;
            margin-bottom: 40px;
        }
    }
}