body {
  font-family: $fnt;
  font-size: $fnt-m;
  font-weight: normal;
  line-height: $lnh-m;
  letter-spacing: $lsp-m;

  color: black;

  @include breakpoint(sm) {
    @include fontformat($fmt-m-m);
  }
}

p,
ul {
  margin-bottom: $lnh-m;

  @include breakpoint(sm) {
    margin-bottom: $lnh-m-m;
  }
}

i,
em {
  letter-spacing: 0;
}

a {
  color: $green;
  text-decoration: none;

  // transition: color .2s;

  &:hover,
  &:focus,
  &:active {
    color: black;
    text-decoration: none;
  }

  &.button {
    color: white;
    background-color: $green;
    border-radius: 4px;
    padding: 5px 15px 7.5px 15px;
  }
}

h1, h2, h3, h4 {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
}

h2 {
  text-transform: uppercase;
  @include fontformat($fmt-l);

  color: $green;

  @include breakpoint(sm) {
    @include fontformat($fmt-l-m);
  }
}

h3 {
  font-weight: bold;
  color: $green;
}


.fnt,
[class^="fnt"] {
  font-family: $fnt;
}

.fnt-s {
  @include fontformat($fmt-s);

  p {
    margin-bottom: $lnh-s;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-s-m);

    p {
      margin-bottom: $lnh-s-m;
    }
  }
}

.fnt-xs {
  @include fontformat($fmt-xs);

  p {
    margin-bottom: $lnh-xs;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-xs-m);

    p {
      margin-bottom: $lnh-xs-m;
    }
  }
}

.fnt-m {
  @include fontformat($fmt-m);

  p {
    margin-bottom: $lnh-m;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-m-m);

    p {
      margin-bottom: $lnh-m-m;
    }
  }
}

.fnt-l {
  @include fontformat($fmt-l);
  // font-feature-settings: 'ss04' 0;

  p {
    margin-bottom: $lnh-l;
  }

  @include breakpoint(sm) {
    @include fontformat($fmt-l-m);

    p {
      margin-bottom: $lnh-l-m;
    }
  }
}

// .fnt-xl {
//   @include fontformat($fmt-xl);
//   font-feature-settings: 'ss04' 0;

//   -ms-hyphens: none;
//   -moz-hyphens: none;
//   -webkit-hyphens: none;
//   hyphens: none;

//   p {
//     margin-bottom: $lnh-xl;
//   }

//   @include breakpoint(sm) {
//     @include fontformat($fmt-xl-m);

//     p {
//       margin-bottom: $lnh-xl-m;
//     }
//   }
// }

.bold,
strong {
  font-weight: bold;
}

.green {
  color: $green;
}

.grey {
  color: $grey;
}

.center {
  text-align: center
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.italic {
  font-style: italic;
}