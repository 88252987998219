main.project {
    padding-top: $lnh-m;

    @include breakpoint(tmd) {
        padding-top: $lnh-m-m;
    }

    #project-nav {
        margin-bottom: 0;

        nav {

            @include breakpoint(sm) {
                overflow-x: scroll;
                overflow-y: hidden;
            }

            ul {
                position: relative;

                list-style: none;
                // display: flex;

                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 48px;
                gap: $gutter;
    
                margin-bottom: 0;

                @include breakpoint(sm) {
                    gap: $mobilePadding / 2;
                }
    
                li {
                    // width: calc(20% - $gutter);
                    // margin-right: $gutter;
    
                    background-color: $green;
                    border-radius: 4px 4px 0 0 ;
    
                    padding: 5px 10px;
                    height: 48px;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include breakpoint(sm) {
                        width: auto;
                        // margin-right: $mobilePadding / 2;
                        height: 29px;
                        border-radius: 4px;
                        white-space: nowrap;
                    }
    
                    &.active {
                        position: relative;
    
                        background-color: white;
                        border: 1px solid $green;
                        border-bottom: 0;

                        @include breakpoint(sm) {
                            border-bottom: 1px solid $green;
                        }
    
                        &:after {
                            content: '';
                            display: block;
    
                            position: absolute;
                            left: -1px;
                            bottom: -9px;
    
                            height: 9px;
                            width: 100%;
                            
                            border-left: 1px solid $green;
                            border-right: 1px solid $green;
    
                            background-color: white;

                            z-index: 1;

                            @include breakpoint(sm) {
                                display: none;
                            }
                        }

                        a {
                            color: $green;
                        }
                    }
    
                    a {
                        color: white;
                    }
                }
            }
        }
    }

    #project-content {
        margin-bottom: $gutter * 2;

        @include breakpoint(sm) {
            padding: 0 $mobilePadding;
            margin-bottom: $mobilePadding * 2;
        }

        .max-width {
            position: relative;

            #project-content-wrapper {
                position: relative;

                border: 1px solid $green;
                border-radius: 0 4px 4px 4px;

                margin-top: 8px;

                @include breakpoint(sm) {
                    border: none;
                }
            }
        }

        #project-title {
            padding-top: 50px;

            @include breakpoint(sm) {
                padding: $lnh-m-m / 2 0;
                hyphens: auto;
            }
        }
    }

    .project-block {
        position: relative;
        padding-bottom: $lnh-m;

        @include breakpoint(sm) {
            padding-bottom: $lnh-m-m;
        }

        &.text-right .wrapper.image {
            padding-right: $colwidth / 2;

            @include breakpoint($contentWidth, 0) {
                padding-right: $absColumnWidth / 2;
            }

            @include breakpoint(sm) {
                padding-right: 0;
            }
        }

        &.text-left .wrapper.image {
            padding-left: $colwidth / 2;

            @include breakpoint($contentWidth, 0) {
                padding-left: $absColumnWidth / 2;
            }

            @include breakpoint(sm) {
                padding-left: 0;
            }
        }
    }

    .block-outline {
        position: relative;
        // width: 1px;
        height: 100%;

        &.left {
            border-left: 1px solid $green;
        }

        &.right {
            border-right: 1px solid $green;
        }
    }

    .article-navigation {
        .nav-wrapper {
            border-top: 2px solid $green;

            .nav-control#next {
                text-align: left;
            }

            .nav-control#prev {
                text-align: right;
            }
        }
    }

    #team {
        #team-members {
            @include breakpoint(tmd) {
                margin-left: -$gutter / 2;
                margin-right: -$gutter / 2;
            }

            .col-5 {
                margin-bottom: $gutter;

                &:nth-child(2n+1) {
                    margin-left: $colwidth;
    
                    @include breakpoint(sm) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}