.tabbed {
    figure { 
        display: block; 
    }

    & > input,
    & figure > div { 
        display: none; 
    }

    & figure > div {
        width: 100%;
    }

    @for $i from 0 through 4 {
        #tab#{$i}:checked ~ figure .tab#{$i} { 
            display: block; 
        }
    }

    nav {
        position: relative;

        display: flex;
        justify-content: space-between;

        label {
            position: relative;
        }
    }
    
}
