header {
    display: flex;
    align-items: center;

    position: sticky;
    top: 0;

    background-color: white;

    height: 75px;

    z-index: 2;

    @include breakpoint(tmd) {
        padding: $spc-v-m-m;
    }

    @include breakpoint(sm) {
        height: $mobileHeaderHeight;
        
        padding: 0;

        z-index: 10;

        .row {
            flex-wrap: nowrap;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        @include breakpoint(sm) {
            padding: $mobilePadding / 2 $mobilePadding;
            border-bottom: 0;
        }

        .row > div {
            display: flex;
            align-items: center;
        }
    }

    ul {
        list-style: none;
        margin-bottom: 0;
    }

    #header-line {
        width: 100%;
        height: 1px;

        background-color: $black;

        margin-top: 14px;

        @include breakpoint(sm) {
            margin-top: $mobilePadding / 2;
        }
    }

    #main-menu {
        justify-content: center;

        @include breakpoint(sm) {
            position: absolute;
            top: $mobileHeaderHeight;
            right: 0;

            display: none;

            &.visible {
                display: block;
            }
        }

        nav {
            width: 100%;

            ul {
                display: grid;//flex;
                justify-content: center;
                gap: 7%;
                grid-auto-flow: column;

                @include breakpoint(sm) {
                    display: block;
                }
                
                li {
                    position: relative;

                    @include breakpoint(sm) {
                        text-align: right;
                        background-color: #eee;
                    }

                    a {
                        color: black;

                        @include breakpoint(sm) {
                            display: inline-block;
                            width: 100%;
                            padding: 9px $mobilePadding;
                        }
                    }

                    &.active {
                        @include breakpoint(sm) {
                            background-color: $grey;
                        }

                        a {
                            color: $green;
                            
                            @include breakpoint(sm) {
                                color: black;
                                font-weight: normal;
                            }
                        }
                    }

                    &:hover {
                        @include breakpoint(sm) {
                            background-color: $grey;
                        }

                        a {
                            color: $green;

                            @include breakpoint(sm) {
                                color: black;
                                font-weight: normal;
                            }
                        }
                    } 
                }
            }
        }
    }

    #language-switch {
        justify-content: flex-end;

        @include breakpoint(sm) {
            flex: 0 0 auto;
        }

        nav {
            background-color: $green;
            color: white;

            line-height: $fnt-m;

            padding: 2px 5px;

            @include breakpoint(sm) {
                padding: 0 2px;
            }

            a {
                color: white;
                vertical-align: 5px;

                @include breakpoint(sm) {
                    vertical-align: 1px;

                    font-size: 15px;
                    line-height: 10px;
                }
            }
        }
    }

    #header-logo {        
        @include breakpoint(sm) {
            flex: 1;
        }

        svg {
            width: 188px;
            height: 39px;

            vertical-align: middle;

            @include breakpoint(sm) {
                height: 19px;
                width: 91px;
                vertical-align: -4px;
            }
        }
    }

    #hamburger {
        margin-left: $mobilePadding;

        cursor: pointer;

        svg {
            width: 19.2px;
            height: 14px;
        }
    }
}