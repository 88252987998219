.grid {
  width: 100%;
  padding: 0 $gutter;

  @include breakpoint(tmd) {
    padding: 0;
  }

  @include breakpoint(xs) {
    padding: 0;
  }

  .grid {
    padding: 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;

  // @include breakpoint(tmd) {
  //   margin-left: -$gutter / 2;
  //   margin-right: -$gutter / 2;
  // }

  @include breakpoint(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

@for $i from 1 through $colcount {
  // $width: $absColumnWidth * $i;
  $width: $colwidth * $i;

  .col-#{$i} {
    flex: 0 0 $width;
    // flex-basis: $width;
    // flex-shrink: 0;
    // flex-grow: 0;
    max-width: $width;
    padding: 0 $gutter / 2;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      // margin: 0 auto;
    }
  }

  // .w-#{$i} {
  //   width: $width;
  // }

  // $width: $colwidth * $i;

  // .rel-col-#{$i} {
  //   flex: 0 0 $width;
  //   max-width: $width;
  //   padding: 0 $gutter / 2;

  //   @include breakpoint(sm) {
  //     flex: 0 0 100%;
  //     max-width: 500px;//100%;
  //     padding: 0;
  //     // margin: 0 auto;
  //   }
  // }
}

html.msie {
  @for $i from 1 through $colcount {
    $width: 12vw * $i;
  
    .col-#{$i} {
      flex: 0 0 $width;
      // flex-basis: $width;
      // flex-shrink: 0;
      // flex-grow: 0;
      max-width: $width;
      padding: 0 $gutter / 2;
  
      @include breakpoint(sm) {
        flex: 0 0 100%;
        max-width: 500px;//100%;
        padding: 0;
        margin: 0 auto;
      }
    }
  }
}