main.blog {
    @include breakpoint(tmd) {
        padding: 0 $spc-v-m-m;
    }
    
    .section-divide {
        margin-top: $lnh-m * 2;
        margin-bottom: $lnh-m;

        border-bottom: 1.5px solid $green;

        @include breakpoint(sm) {
            margin-top: $lnh-m-m * 1;
            margin-bottom: $lnh-m-m;
        }

        h2 {
            @include fontformat($fmt-m);
        }
    }

    article {
        padding: $gutter;
        border: 1px solid white;

        margin-bottom: $gutter;

        @include breakpoint(sm) {
            padding: $mobilePadding;
            border: 1px solid $grey;
        }

        &:hover {
            border: 1px solid $grey;
            border-radius: 4px;
        }

        h2 {

        }

        h3,
        p {
            color: black;
        }

        p {
            margin-top: $lnh-m / 2;
        }

        .wrapper {
            display: flex;

            @include breakpoint(sm) {
                display: block;
            }

            .left {
                flex: 0 0 33.33%;

                @include breakpoint(sm) {
                    margin-bottom: $lnh-m-m / 2;
                }
            }

            .right {
                flex: 0 0 66.66%;
                padding-left: $gutter;

                @include breakpoint(sm) {
                    padding-left: 0;
                }
            }
        }
    }
}