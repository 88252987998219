* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

html {
  // Always show the vertical scrollbar to prevent `jumps`
  // of page content after navigating from a very short page
  // that does not scroll to a long pager
  overflow-y: scroll;

  // Setting this to `default` prevents the cursors from
  // changing when hovering blocks of text or whitespace,
  // which is less distracting
  cursor: default;

  // Neccessary for sticks footer.
  position: relative;
  min-height: 100%;

  // Prevent adjustments of font size after orientation
  // changes in IE and iOS.
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
}

html, body {
  width: 100%;
}

body {
  
  // &.disable-scroll {
  //   position: fixed;
  //   width: 100%;
  // }

  // @include breakpoint(sm) {
  //   overflow: hidden;
  // }
  
}

figure {
  line-height: 0;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

@include breakpoint(sm) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}
