.article-navigation {
    .nav-wrapper {
        position: relative;

        width: 100%;
        display: flex;
        justify-content: center;

        padding-top: $lnh-m / 2;
        border-top: 1px solid $green;

        list-style: none;

        & > div {
            text-align: center;
        }

        .nav-control {
            position: relative;
             
            flex: 1;

            &:hover {
                svg path {
                    fill: black;
                }
            }

            &.disabled {
                color: $grey;

                svg path {
                    fill: $grey;
                }
            }

            &#prev {
                padding-right: $lnh-m;

                .nav-icon {
                    text-align: right;

                    svg {
                        transform: rotate(90deg);
                    }
                }
            }

            &#next {
                padding-left: $lnh-m;

                .nav-icon {
                    text-align: left;

                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .nav-current {
            flex: 0 0 auto;
            padding: 0 $lnh-m;

            @include breakpoint(sm) {
                padding: 0 $lnh-m-m / 2;
            }
        }

        .nav-icon {
            position: absolute;
            // bottom: 0;
            top: 0;
            left: 0;
            width: 100%;

            svg {
                vertical-align: middle;

                path {
                    fill: $green;
                }
            }
        }
    }
}