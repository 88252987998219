@mixin columnWidth($col) {
  width: calc((75vw - #{$gutter * 2.75}) / #{$colcount} * #{$col});
}

// based on
//https://medium.com/developing-with-sass/creating-a-dead-simple-sass-mixin-to-handle-responsive-breakpoints-889927b37740#.721a545lo

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    $class: $min;

    @if $class == xs {
      @media (max-width: $screen-xs-max) {
        @content;
      }
    }
    @else if $class == sm {
      @media (max-width: $screen-sm-max) {
        @content;
      }
    }
    @else if $class == md {
      @media (max-width: $screen-md-max) and (orientation: portrait) {
        @content;
      }
    }
    @else if $class == tmd {
      @media (max-width: $screen-md-max) and (orientation: landscape) {
        @content;
      }
    }
    @else if $class == lg {
      @media (min-width: $screen-lg-min) {
        @content;
      }
    }
    @else if $class == dsk {
      @media (min-width: $screen-sm-max) {
        @content;
      }
    }
    @else if $class == por {
      @media (orientation: portrait) and (max-width: $screen-sm-max){
        @content;
      }
    }
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
  }
  @else if $type == number {
    $query: "all" !default;

    @if $min != 0 and $max != 0 {
      $query: "(min-width: #{$min}) and (max-width: #{$max})";
    }
    @else if $min != 0 and $max == 0 {
      $query: "(min-width: #{$min})";
    }
    @else if $min == 0 and $max != 0 {
      $query: "(max-width: #{$max})";
    }

    @media #{$query} {
      @content;
    }
  }
}

/// Font Format

@mixin fontformat($list) {
  font-size: nth($list, 1);
  line-height: nth($list, 2);
  letter-spacing: nth($list, 3);
}

/// Absolute Default

@mixin absolute($position: absolute, $top: 0, $right: auto, $bottom: auto, $left: 0, $width: 100%, $height: 100%) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin fontFace($family,$src,$style: normal,$weight: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$src}.ttf') format('truetype'); // IE9 compat
		//src: //url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
			// url('#{$src}.woff') format('woff'), // standards
      // url('#{$src}.woff2') format('woff2'); // standards
			// url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
			// url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

		font-style: $style;
		font-weight: $weight;
	}
}

/// Center Element

@mixin center($horizontal: true, $vertical: true) {
  position: absolute;

  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }
  @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}

/// Create Animations

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($animate) {
  $max: length($animate);
  $animations: "";

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }

  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}
