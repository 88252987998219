footer {
    background-color:white;// #D9D9D9;

    padding: 68px 0;

    overflow-x: hidden;

    @include breakpoint(sm) {
        padding: 26px $mobilePadding;
    }

    #footer-divider {
        // padding: 0 $gutter;
        margin-bottom: $lnh-m * 2;

        // @include breakpoint(tmd) {
        //     padding: 0 $spc-v-m-m;
        // }

        @include breakpoint(sm) {
            // padding: 0;
            margin-bottom: $lnh-m-m * 2;
        }

        // .grid {
        //     padding: 0  $gutter;

        //     @include breakpoint(sm) {
        //         padding: 0;
        //     }
        // }
    }

    // .footer-divider-line {
    //     height: 1px;
    //     background-color: black;
    // }

    img#logo {
        width: 182px;

        margin-bottom: $lnh-m * 2;

        @include breakpoint(sm) {
            width: 136px;
            margin-bottom: $lnh-m-m;
        }
    }

    #sponsors {
        figure {
            margin-bottom: 37px;

            @include breakpoint(sm) {
                display: inline-block;
                width: 28%;
                margin-right: 6%;
                vertical-align: top;

                &:last-child {
                    margin-right: 0;
                }
            }

            img {
                min-width: 150px;

                @include breakpoint(sm) {
                    min-width: auto;
                    width: 100%;
                }
            }
        }
    }

    #contact {
        a {
            color: black;
            text-decoration: underline;
        }
    }
}