.mobile {
  display: none !important;

  @include breakpoint(sm) {
  	display: block  !important;
  }
}

.desktop {
  display: block !important;
  
  @include breakpoint(sm) {
  	display: none !important;
  }
}

.desktop-flex {
	display: flex !important;

	@include breakpoint(sm) {
		display: none !important;
	}
}

.desktop-inline {
	display: inline !important;

	@include breakpoint(sm) {
		display: none !important;
	}
}

.noselect {
  user-select: none;
}

.hidden {
  display: none;
  visibility: hidden;
}

.max-width {
  width: 100%;
  max-width: $contentWidth;
  // max-width: calc(100vw - ((100vw / 12) * 2 + #{$gutter * 2}));
  margin: 0 auto;

  @include breakpoint(0, $screen-md-max) {
    max-width: $contentWidth;
  }

  @include breakpoint(tmd) {
      padding: 0 $gutter;
  }
}

.headline {
  position: relative;

  margin-bottom: $lnh-m / 2;

  mix-blend-mode: multiply;

  &.fnt-l {
    &:after {
      bottom: $lnh-l * 0.2;
    }
  }

  p {
    margin-bottom: 0;
  }

  h2, h3, h4 {
    display: inline-block;
    background-color: white;
    padding-right: 0.25em;
  }
    
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: $green;
    position: absolute;
    bottom: $lnh-m * 0.25;
    z-index: -1;
  }
}

.channel-icon {
  width: 28px;//2em;
  height: 28px;//2em;
  background-color: $green;
  color: white;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $fnt-xs;
}