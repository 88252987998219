.grid-grid {
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $gutter;

  padding: 0 $gutter;

  @include breakpoint(tmd) {
    padding: 0;
  }

  @include breakpoint(xs) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  .grid-subgrid {
    display: grid;
    grid-template-columns: subgrid;
  }
}

@for $i from 1 through $colcount {
  .col-span-#{$i} {
    grid-column: span $i / span $i;

    @include breakpoint(sm) {
        grid-column: span $colcount / span $colcount;
    }
  }
}

@for $i from 1 through $colcount {
  .col-start-#{$i} {
    grid-column-start: $i;

    @include breakpoint(sm) {
        grid-column-start: 1;
    }
  }
}